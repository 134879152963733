import React from "react";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import MenuItem from "../../base/MenuItem";
import { useSelector } from "react-redux";

export default function CreditCardRefundForm(props) {
  const selectedClub = useSelector((state) => state.auth.selectedClub);
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {selectedClub.clubConfig.creditCardRefundWithPayments && (
        <Grid item xs={12}>
          <TextField
            select
            label="מספר תשלומים"
            fullWidth
            variant="standard"
            sx={{ maxWidth: 300 }}
            value={props.paymentMethod.numberOfPayments}
            onChange={(e) =>
              props.onChange({ numberOfPayments: e.target.value })
            }
          >
            {[...Array(props.maxNumberOfPayments || 1).keys()].map((i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1} תשלומים
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.paymentMethod.localRefund}
              onChange={(e) =>
                props.onChange({ localRefund: e.target.checked })
              }
            />
          }
          label="ללא ביצוע החזר בפועל מול חברת האשראי (עליכם לבצע את ההחזר ישירות מול חברת האשראי)"
        />
      </Grid>
    </Grid>
  );
}
